<template lang="pug">
div
  .page-header
    .row
      nav-pills
      .col-md-5
        h1 {{cliente}}
      .col-md-7
  .modal-body.modal-fixed
    form.form-horizontal(@submit.prevent="gravar")
      .form-group
        label.col-sm-3.control-label.no-padding-right(for='data')  Data
        .col-sm-3
          flat-pickr.col-xs-10.col-sm-3(
            v-model="agenda.data",
            :config="config",
            class="form-control",
            placeholder="Data",
            name="date")
      .form-group
        label.col-sm-3.control-label.no-padding-right(for='hora')  Hora
        .col-sm-9
          the-mask.col-xs-10.col-sm-3.hora(type='text', v-model='agenda.hora', placeholder='Hora', maxlength='5', :mask="['##:##']", required='')
      .form-group
        select-form(v-model='agenda.status_id', name='Status', url='/status/agenda', id='id', titulo='nome', layout='true', required)
      .form-group
        select-form(v-model='agenda.area_id', name='Área', url='/areas', id='id', titulo='title', layout='true', required)
      .form-group
        label.col-sm-3.control-label.no-padding-right(for='mensagem')  Mensagem
        .col-sm-9
          textarea.form-control.autosize(v-model='agenda.mensagem', placeholder='Mensagem', v-autosize='agenda.mensagem')
      .modal-footer
        button.btn.btn-warning(type='submit', @click.prevent='gravar("clientes")')
          | Cadastrar -&nbsp;
          i.fa.fa-address-book-o
          |  Cliente
        button.btn.btn-primary(type='submit')
          | Cadastrar -&nbsp;
          i.fa.fa-calendar-check-o
          |  Agenda
        button.btn.btn-success(type='submit', @click.prevent='gravar("vendas")')
          | Cadastrar -&nbsp;
          i.fa.fa-cart-plus
          |  Venda
  .page-header
    .row
      .col-md-12
        h1 Histórico Agenda
  .widget-main
    kendo-datasource(ref="datasource1",
      :transport-read="{ url: `${$url}/agenda/historico/${$route.params.id}`, beforeSend: onBeforeSend }",
      :page-size='5',
      :schema-parse="parse"
    )
    kendo-grid(:data-source-ref="'datasource1'", :pageable='true')
      kendo-grid-column(:field="'mensagem'", :title="'Mensagem'", :width='250')
      kendo-grid-column(:field="'u_nome'", :title="'Usuário'")
      kendo-grid-column(:field="'data_resp'", :title="'Data'", :width='300')
      kendo-grid-column(:field="'area'", :title="'Área'", :width='180')
      kendo-grid-column(:field="'s_nome'", :title="'Status'", :width='180', :template="statusTemplate")
</template>

<script>
import Vue from 'vue'
import {mapGetters, mapActions} from 'vuex'
import '@progress/kendo-ui'
import '@progress/kendo-theme-default/dist/all.css'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import {Portuguese} from 'flatpickr/dist/l10n/pt.js'
import navPills from '../../components/NavPills'
import VueAutosize from 'vue-autosize'
import {TheMask} from 'vue-the-mask'
import moment from 'moment'
import 'clockpicker/dist/jquery-clockpicker.min'
import 'clockpicker/dist/bootstrap-clockpicker.min.css'
import SelectForm from '@/components/form/SelectForm'
import { Grid, GridInstaller } from '@progress/kendo-grid-vue-wrapper'
import { DataSource, DataSourceInstaller } from '@progress/kendo-datasource-vue-wrapper'

moment.locale('pt-BR')

Vue.use(VueAutosize)
Vue.use(GridInstaller)
Vue.use(DataSourceInstaller)

export default {
  components: {
    navPills,
    TheMask,
    flatPickr,
    SelectForm,
    Grid,
    DataSource
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  data () {
    return {
      cliente: '',
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altInput: true,
        altFormat: 'd F Y',
        dateFormat: 'Y-m-d',
        locale: Portuguese
      },
      agenda: {
        data: '',
        hora: moment().format('LT'),
        status_id: '',
        area_id: '',
        mensagem: ''
      }
    }
  },
  methods: {
    ...mapActions([
      'getLogsTotal'
    ]),
    parse (d) {
      d.map(i => {
        i.data_resp = moment(i.data_resp).format('L LT')
        return i
      })
      return d
    },
    getCliente () {
      this.$axios(`/clientes/nome/${this.$route.params.id}`).then(resp => {
        this.cliente = resp.data.cliente.toUpperCase()
      })
    },
    gravar (action) {
      let body = this.agenda
      body.usuario_id = this.user.id
      body.cliente_id = this.$route.params.id
      this.$axios.post('/agenda/resposta', body).then(() => {
        this.$refs.datasource1.kendoWidget().read()
        this.$swal.fire({
          title: 'Sucesso!',
          text: 'Cadastrado com sucesso!',
          icon: 'success',
          timer: 2000
        })
        this.getDateNew()
        let hora = document.querySelector('.hora')
        setTimeout(() => {
          hora.value = ''
        }, 300)
        this.agenda.hora = null
        this.agenda.status_id = ''
        this.agenda.mensagem = ''
        this.getLogsTotal(this.user.id)
        switch (action) {
          case 'clientes':
            this.$router.push('/sistema/clientes')
            return
          case 'vendas':
            this.$router.push(`/sistema/clientes/${this.$route.params.id}/vendas`)
            return
          default:
            return null
        }
      })
    },
    statusTemplate (e) {
      return {
        template: Vue.component('temp', {
          template: `<span style='display: block;padding: 5px;margin: 18px' :style='[{ "background-color" : templateArgs.s_cor }, {color : getColorByBgColor(templateArgs.s_cor)}]'>{{ templateArgs.s_nome }}</span>`,
          data () {
            return {
              templateArgs: {}
            }
          },
          methods: {
            hexToRgb (hex) {
              let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
              return result ? {
                r: parseInt(result[1], 16),
                g: parseInt(result[2], 16),
                b: parseInt(result[3], 16)
              } : null
            },
            getColorByBgColor (hexcolor) {
              let r = this.hexToRgb(hexcolor) ? this.hexToRgb(hexcolor).r : 'ff'
              let g = this.hexToRgb(hexcolor) ? this.hexToRgb(hexcolor).g: '00'
              let b = this.hexToRgb(hexcolor) ? this.hexToRgb(hexcolor).b : '00'
              let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000
              return (yiq >= 128) ? 'black' : 'white'
            }
          }
        }),
        templateArgs: e
      }
    },
    getDateNew () {
      this.$axios('/date').then(resp => {
        this.agenda.data = moment(resp.data.date).format('YYYY-MM-DD')
      })
    },
    onBeforeSend (xhr) {
      xhr.setRequestHeader(
        'Authorization',
        'Bearer ' + localStorage.getItem('token')
      )
    }
  },
  created () {
    this.getDateNew()
    this.getCliente()
  },
  mounted () {
    // eslint-disable-next-line no-undef
    $('.hora').clockpicker({
      placement: 'bottom',
      align: 'left',
      autoclose: true,
      'default': 'now'
    }).on('change', e => {
      this.agenda.hora = e.target.value
    })
  }
}
</script>

<style lang="stylus" scoped>

</style>
